/* eslint-disable array-callback-return */
<template>
  <meta charset="utf-8">
	<div class="container">
		<Header :title="'Configuration'" />
		<hr />
		<div class="row">
			<div class="col-12">
				<a class="btn menu" data-bs-toggle="collapse" :href="'#mailsystems'" role="button" aria-expanded="false" aria-controls="mailsystems">
					<h4>Mailsystems:</h4>
				</a>
				<hr />
				<div class="collapse" id="mailsystems">
					<ul>
						<li v-for="mailsystem in mailsystems" :key="mailsystem.name">
							<System :system="mailsystem" />	
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<a class="btn menu" data-bs-toggle="collapse" :href="'#distributors'" role="button" aria-expanded="false" aria-controls="distributors">
					<h4>Distributors:</h4>
				</a>
				<hr />
				<div class="collapse" id="distributors">
					<ul>
						<li v-for="distributor in distirbutorList" :key="distributor.linkname">
							<Distributor :dist="distributor" :systems="mailsystems" />
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<a class="btn menu" data-bs-toggle="collapse" :href="'#tracking'" role="button" aria-expanded="false" aria-controls="tracking">
					<h4>Trackinglinks:</h4>
				</a>
				<hr />
				<div class="collapse" id="tracking">
					<h5>Old: (links to replace)</h5>
					<ul>
						<li v-for="(link, index) in trackinglinks.old" :key="index">
							<div class="input-group mb-3">
								<input type="text" class="form-control" aria-label="Recipient's username" :name="link" :value="link">
								<div class="input-group-append">
									<button class="btn btn-outline-secondary" type="button">OK</button>
									<button class="btn btn-outline-secondary" type="button">Delete</button>
								</div>
							</div>
						</li>

					</ul>
					<hr>
					<h5>New: (link who replacing the old)</h5>
					<div class="input-group mb-3">
						<input type="text" class="form-control" aria-label="Recipient's username" :name="trackinglinks.new" :value="trackinglinks.new">
						<div class="input-group-append">
							<button class="btn btn-outline-secondary" type="button">OK</button>
							<button class="btn btn-outline-secondary" type="button">Delete</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<a class="btn menu" data-bs-toggle="collapse" :href="'#TPNGdom'" role="button" aria-expanded="false" aria-controls="TPNGdom">
					<h4>TPNG DOM Elemente:</h4>
				</a>
				<hr />
				<div class="collapse" id="TPNGdom">
					<ul>
						<li>
							<b>TPNG Header</b>
							<div class="container">
								<div class="row">
									<div class="col-6">
										<table v-for="entry in headelement.headerdom" :key="entry">
											<tbody>
												<span v-if="typeof entry === 'object'">
													<tr v-for="(value, name) in entry" :key="value">
														<td width="100">{{ name }}</td>
														<td width="50%">{{ value }}</td>
													</tr>
												</span>
												<span v-else>
													<tr>
														<td width="100">{{ entry.name }}</td>
														<td width="50%">{{ entry.value }}</td>
													</tr>
												</span>
											</tbody>
										</table>
									</div>
									<div class="col-6">
										<iframe :srcdoc="Mustache.render(headelement.headertemplate, headelement.headerdom)"></iframe>
									</div>
								</div>
							</div>
						</li>
						<li>
							<b>TPNG Footer</b>
							<div class="container">
								<div class="row">
									<div class="col-6">
										<table v-for="entry in footelement.footerdom" :key="entry">
											<tbody>
												<span v-if="typeof entry === 'object'">
													<tr v-for="(value, name) in entry" :key="value">
														<td width="100">{{ name }}</td>
														<td width="50%">{{ value }}</td>
													</tr>
												</span>
												<span v-else>
													<tr>
														<td width="100">{{ entry.name }}</td>
														<td width="50%">{{ entry.value }}</td>
													</tr>
												</span>
											</tbody>
										</table>
									</div>
									<div class="col-6">
										<iframe height="600" :srcdoc="Mustache.render(footelement.footertemplate, footelement.footerdom)"></iframe>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<a class="btn menu" data-bs-toggle="collapse" :href="'#tritontags'" role="button" aria-expanded="false" aria-controls="tritontags">
					<h4>Triton-Tags:</h4>
				</a>
				<hr />
				<div class="collapse" id="tritontags">
					<table>
						<thead>
							<tr>
								<th><b>Platzhalter</b></th>
								<th><b>Tag</b></th>
								<th><b>Datenbankname</b></th>
							</tr>	
						</thead>
						<tbody>
							<tr v-for="tag in tritontags" :key="tag.name">
								<td>{{ tag.example }}</td>
								<td>{{ tag.tag }}</td>
								<td>{{ tag.name }}</td>
								
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Mustache from "mustache";
import { ref, onMounted } from "vue";
import System from "../components/System.vue";
import Distributor from "../components/Dist.vue";
import Header from "../components/Header.vue";
import data from "../data/db.json";
import _ from 'lodash';
import {headerdom, headertemplate} from "../components/mailheader.js";
import {footerdom, footertemplate} from "../components/mailfooter.js";


export default {
	components: {
		System,
		Distributor,
		Header
	},
	setup() {
		
		const mailsystems = ref(data.mailsystem);
		const trusteddialog = ref(data.trusteddialog);
		const trackinglinks = ref(data.trackinglinks);
		const headelement = ref({headerdom, headertemplate});
		const footelement = ref({footerdom, footertemplate});	
		const tritontags = ref(data.tritontags);
		const distirbutorList = ref([]);

		/**
		 * Iterate over each mailsystem to find each distributor.
		 * Add the Distributor if do not exist in the distributor list.
		 */
		function iterrateMailsystemsForDistributors() {
			mailsystems.value.forEach(system => {
				system.distributors.forEach(dist => {
					let distributor = {
						imprint: dist.imprint,
						linkname: dist.linkname,
						name: dist.name,
						style: dist.style,
						tdhead: dist.tdhead,
					};
					let exists = distirbutorList.value.find(d => {
						return d.name === distributor.name;
					});
					
					distributor["systems"] = [system.name];
					
					if(exists) {
						// eslint-disable-next-line array-callback-return
						distirbutorList.value.find(d => {
							// eslint-disable-next-line array-callback-return
							if(d.systems.indexOf(system.name) >= 0) return;
							d.systems.push(system.name);
							// eslint-disable-next-line array-callback-return
							if(!dist.customfields) return;
							d.customfields = dist.customfields;
						});
					} else {
						distirbutorList.value.push(distributor);
					}
				});
			});
			distirbutorList.value = _.orderBy(distirbutorList.value, 'name', 'asc');

			/**
			 * find the right td header for each distributor
			 */
			distirbutorList.value.forEach(distributor => {
				distributor.trusteddialog = trusteddialog.value.find(dist => {
					return dist.name === distributor.tdhead;
				});
			});

		}
		onMounted(() => {
			iterrateMailsystemsForDistributors();
		});

		return {
			mailsystems,
			distirbutorList,
			trusteddialog,
			trackinglinks,
			headerdom,
			headertemplate,
			headelement,
			footelement,
			tritontags,
			Mustache
		};
	},
};

</script>

<style lang="scss" scoped>

	@import "./src/assets/styles/color.scss";

	.container {
		padding: 8px;
		.btn.menu {
			display: block;
		}
		h4 {
			font-size: 1rem;
			text-align: left;
		}
		hr {
			border-color: $font;
			border-top-width: 0px;
		}
		ul {
			list-style: none;
			text-align: left;
			padding: 0;
			li {
				font-size: 1rem;
				margin: 16px 0;
				&::after {
					content: " ";
					display: block;
					border-bottom: 1px solid $background;
				}
				& > a {
					padding-bottom: 4px;
					&:focus {
						box-shadow: none !important;
					}
				}
				.input-group .btn {
					background-color: $confirm;
					color: white;
				}
			}
		}
		table {
			width: 100%;
			thead {
				background-color: $tiles;
				color: white;
				tr th{
					padding: 8px 16px;
				}
			}
			tbody {
				tr {
					td {
						padding: 8px 16px;	
					}
					&:nth-child(even) {
						background-color: $background;
					}
				}
			}
		}
		#tritontags {
			table {
				width: 100%;
				border: 1px solid $background;
				margin-bottom: 4rem;
				tr {
					height: 3rem;
					border: 1px solid $background;
				}
				td {
					border: 1px solid $background;
				}
			}
		}
		
	}
</style>
