<template>
<meta charset="utf-8">
<div class="container">
	<a class="btn" data-bs-toggle="collapse" :href="'#'+system.name" role="button" aria-expanded="false" :aria-controls="system.name">
		<h5>{{ system.name }}</h5>
	</a>
	<div class="collapsecard card-body" :id="system.name">
		<div class="row">
			<div class="col-12">
				<b>Variables:</b>
				<table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Variable</th>
							<th>Triton-Platzhalter</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="variable in system.variables" :key="variable.name">
							<td>{{ variable.name }}</td>
							<td>{{ variable.variable }}</td>
							<td>{{ variable.ttag }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<br />
		<div class="row" v-if="system.customfields">
			<div class="col-12">
				<b>Customfields:</b>
				<table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Variable</th>
							<th>Triton-Platzhalter</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="customfield in system.customfields" :key="customfield.name">
							<td>{{ customfield.name }}</td>
							<td>{{ customfield.variable }}</td>
							<td>{{ customfield.ttag }}</td>
						</tr>
					</tbody>
					
				</table>
			</div>
		</div>
	</div>
</div>


</template>

<script>


export default {
	props: {
		system: Object
	}
};
</script>
<style lang="scss" scoped>

	@import "./src/assets/styles/color.scss";

	ul {
		list-style: none;
	}
</style>