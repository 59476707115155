<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<a class="btn menu" data-bs-toggle="collapse" :href="'#' + (dist.tdhead||dist.linkname||dist.name.toLowerCase())" role="button" aria-expanded="false" :aria-controls="dist.name">
				<h5>{{ dist.name }}</h5>
				</a>
			</div>
		</div>
		<div class="row mailsystems">
			<div class="col" v-for="value in systems" :key="value">
				<center><b>{{ value.name }}</b></center>
				<center v-if="dist.systems.indexOf(value.name) !== -1"><i class="fas fa-check yes"></i></center>
				<center v-else><i class="fas fa-times no"></i></center>
			</div>
		</div>
		<br />
		
		<div class="collapse card card-body" :id="dist.tdhead||dist.linkname||dist.name.toLowerCase()">
			<div class="row">
				<div class="col">
					<b>Linkname:</b><br />
					{{ dist.linkname || dist.tdhead }}
				</div>
				<div class="col">
					<b>Impressum:</b><br />
					<a :href="dist.imprint" target="_blank">{{ dist.imprint }}</a>
				</div>
				<div class="col">
					<b>TrustedDialog-Name:</b><br />
					{{ dist.tdhead }}
				</div>
			</div>
			<br />
			<div class="row styles">
				<div class="col-12">
					<b>Stylings:</b>
					<ul>
						<li v-for="(value, name) in dist.style" :key="value">
							{{ name }} : {{ value }}
						</li>
					</ul>
				</div>
			</div>
			<div class="row custom" v-if="dist.customfields">
				<div class="col-12">
					<b>Mailsolution Special Custimfields:</b>
					<ul>
						<li v-for="(value, name) in dist.customfields" :key="value">
							{{ name }} : {{ value }}
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="row" >
			<div class="col-12" v-if="dist.trusteddialog">
				<iframe width="100%" :srcdoc="dist.trusteddialog.body.advice"></iframe>
			</div>
		</div>
	</div>
</template>

<script>


export default {
	props: {
		dist: Object,
		systems: Array
	}
};
</script>

<style lang="scss" scoped>
@import "./src/assets/styles/color.scss";
ul {
	list-style: none;
}
.yes {
	color: $confirm; 
}
.no {
	color: $error;
}
.custom ul li {
	font-size: 0.8rem;
	margin-bottom: 4px;
}
</style>